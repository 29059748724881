define("discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-test", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModalBody
    @title="chat_integration.test_modal.title"
    @id="chat_integration_test_modal"
    {{on "keyup" this.handleKeyUp}}
  >
    <div>
      <form {{action "send" on="submit"}}>
        <table>
          <tbody>
            <tr class="input">
              <td class="label">
                <label for="channel">
                  {{i18n "chat_integration.test_modal.topic"}}
                </label>
              </td>
              <td>
                <ChooseTopic @selectedTopicId={{this.model.topic_id}} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </DModalBody>
  
  <div class="modal-footer">
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      <DButton
        @class="btn-primary btn-large"
        @id="send-test"
        @title="chat_integration.test_modal.send"
        @action={{action "send"}}
        @label="chat_integration.test_modal.send"
        @disabled={{this.sendDisabled}}
      />
      <DButton
        @class="btn-large"
        @title="chat_integration.test_modal.close"
        @action={{route-action "closeModal"}}
        @label="chat_integration.test_modal.close"
      />
    </ConditionalLoadingSpinner>
  </div>
  */
  {
    "id": "RdNtdD1o",
    "block": "[[[8,[39,0],[[4,[38,1],[\"keyup\",[30,0,[\"handleKeyUp\"]]],null]],[[\"@title\",\"@id\"],[\"chat_integration.test_modal.title\",\"chat_integration_test_modal\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[11,\"form\"],[4,[38,2],[[30,0],\"send\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n      \"],[10,\"table\"],[12],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n          \"],[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n              \"],[10,\"label\"],[14,\"for\",\"channel\"],[12],[1,\"\\n                \"],[1,[28,[35,3],[\"chat_integration.test_modal.topic\"],null]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n              \"],[8,[39,4],null,[[\"@selectedTopicId\"],[[30,0,[\"model\",\"topic_id\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@class\",\"@id\",\"@title\",\"@action\",\"@label\",\"@disabled\"],[\"btn-primary btn-large\",\"send-test\",\"chat_integration.test_modal.send\",[28,[37,2],[[30,0],\"send\"],null],\"chat_integration.test_modal.send\",[30,0,[\"sendDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@class\",\"@title\",\"@action\",\"@label\"],[\"btn-large\",\"chat_integration.test_modal.close\",[28,[37,7],[\"closeModal\"],null],\"chat_integration.test_modal.close\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"d-modal-body\",\"on\",\"action\",\"i18n\",\"choose-topic\",\"conditional-loading-spinner\",\"d-button\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-test.hbs",
    "isStrictMode": false
  });
});