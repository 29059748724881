define("discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-channel-error", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModalBody @id="chat_integration_error_modal">
    <h4>{{i18n model.error_key}}</h4>
    <pre>{{model.error_info}}</pre>
  </DModalBody>
  */
  {
    "id": "Ps4FNNzA",
    "block": "[[[8,[39,0],null,[[\"@id\"],[\"chat_integration_error_modal\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[12],[1,[28,[35,1],[[30,0,[\"model\",\"error_key\"]]],null]],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,[30,0,[\"model\",\"error_info\"]]],[13],[1,\"\\n\"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-channel-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-channel-error.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-modal-body\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/templates/modal/admin-plugins-chat-integration-channel-error.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});