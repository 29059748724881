define("discourse/plugins/discourse-chat-integration/admin/controllers/admin-plugins-chat-integration-provider", ["exports", "@ember/controller", "discourse/lib/show-modal", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _showModal, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const MODALS = {
    editChannel: "admin-plugins-chat-integration-edit-channel",
    testChannel: "admin-plugins-chat-integration-test",
    editRule: "admin-plugins-chat-integration-edit-rule",
    channelError: "admin-plugins-chat-integration-channel-error"
  };
  let AdminPluginsChatIntegrationEditRule = _exports.default = (_class = class AdminPluginsChatIntegrationEditRule extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalShowing", _descriptor, this);
    }
    get anyErrors() {
      const channels = this.model.channels;
      let anyErrors = false;
      channels.forEach(channel => {
        if (channel.error_key) {
          anyErrors = true;
        }
      });
      return anyErrors;
    }
    triggerModal(model, modal) {
      this.modalShowing = true;
      (0, _showModal.default)(modal, {
        model,
        admin: true
      });
    }
    createChannel() {
      return this.triggerModal({
        channel: this.store.createRecord("channel", {
          provider: this.model.provider.id,
          data: {}
        }),
        provider: this.model.provider
      }, MODALS.editChannel);
    }
    editChannel(channel) {
      return this.triggerModal({
        channel,
        provider: this.model.provider
      }, MODALS.editChannel);
    }
    testChannel(channel) {
      return this.triggerModal({
        channel
      }, MODALS.testChannel);
    }
    createRule(channel) {
      return this.triggerModal({
        rule: this.store.createRecord("rule", {
          channel_id: channel.id,
          channel
        }),
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      }, MODALS.editRule);
    }
    editRuleWithChannel(rule, channel) {
      return this.triggerModal({
        rule,
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      }, MODALS.editRule);
    }
    showError(channel) {
      return this.triggerModal({
        channel
      }, MODALS.channelError);
    }
    refresh() {
      this.send("refreshProvider");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalShowing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "testChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "testChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRule", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editRuleWithChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editRuleWithChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class);
});