define("discourse/plugins/discourse-chat-integration/discourse/routes/transcript", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse", "@ember/runloop"], function (_exports, _ajax, _ajaxError, _discourse, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Trascript extends _discourse.default {
    model(params) {
      if (this.currentUser) {
        const secret = params.secret;
        this.replaceWith("discovery.latest").then(e => {
          if (this.controllerFor("navigation/default").get("canCreateTopic")) {
            (0, _runloop.next)(() => {
              (0, _ajax.ajax)(`chat-transcript/${secret}`).then(result => {
                e.send("createNewTopicViaParams", null, result["content"], null, null, null);
              }, _ajaxError.popupAjaxError);
            });
          }
        });
      } else {
        this.session.set("shouldRedirectToUrl", window.location.href);
        this.replaceWith("login");
      }
    }
  }
  _exports.default = Trascript;
});